/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      ownerAccounts
      topic
      messages {
        items {
          id
          ownerAccounts
          chatID
          createdAt
          profile
          body
          attachments {
            items {
              id
              ownerAccounts
              messageID
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      ownerAccounts
      topic
      messages {
        items {
          id
          ownerAccounts
          chatID
          createdAt
          profile
          body
          attachments {
            items {
              id
              ownerAccounts
              messageID
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      ownerAccounts
      topic
      messages {
        items {
          id
          ownerAccounts
          chatID
          createdAt
          profile
          body
          attachments {
            items {
              id
              ownerAccounts
              messageID
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      ownerAccounts
      chatID
      createdAt
      profile
      body
      attachments {
        items {
          id
          ownerAccounts
          messageID
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      ownerAccounts
      chatID
      createdAt
      profile
      body
      attachments {
        items {
          id
          ownerAccounts
          messageID
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      ownerAccounts
      chatID
      createdAt
      profile
      body
      attachments {
        items {
          id
          ownerAccounts
          messageID
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createChatAttachment = /* GraphQL */ `
  mutation CreateChatAttachment(
    $input: CreateChatAttachmentInput!
    $condition: ModelChatAttachmentConditionInput
  ) {
    createChatAttachment(input: $input, condition: $condition) {
      id
      ownerAccounts
      messageID
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateChatAttachment = /* GraphQL */ `
  mutation UpdateChatAttachment(
    $input: UpdateChatAttachmentInput!
    $condition: ModelChatAttachmentConditionInput
  ) {
    updateChatAttachment(input: $input, condition: $condition) {
      id
      ownerAccounts
      messageID
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatAttachment = /* GraphQL */ `
  mutation DeleteChatAttachment(
    $input: DeleteChatAttachmentInput!
    $condition: ModelChatAttachmentConditionInput
  ) {
    deleteChatAttachment(input: $input, condition: $condition) {
      id
      ownerAccounts
      messageID
      body
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      tag
      label
      address
      phone
      calendarsByInstrument
      consultCalendar
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      tag
      label
      address
      phone
      calendarsByInstrument
      consultCalendar
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      tag
      label
      address
      phone
      calendarsByInstrument
      consultCalendar
      createdAt
      updatedAt
    }
  }
`;
export const createInstrument = /* GraphQL */ `
  mutation CreateInstrument(
    $input: CreateInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    createInstrument(input: $input, condition: $condition) {
      id
      tag
      label
      createdAt
      updatedAt
    }
  }
`;
export const updateInstrument = /* GraphQL */ `
  mutation UpdateInstrument(
    $input: UpdateInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    updateInstrument(input: $input, condition: $condition) {
      id
      tag
      label
      createdAt
      updatedAt
    }
  }
`;
export const deleteInstrument = /* GraphQL */ `
  mutation DeleteInstrument(
    $input: DeleteInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    deleteInstrument(input: $input, condition: $condition) {
      id
      tag
      label
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const createSkillTemplate = /* GraphQL */ `
  mutation CreateSkillTemplate(
    $input: CreateSkillTemplateInput!
    $condition: ModelSkillTemplateConditionInput
  ) {
    createSkillTemplate(input: $input, condition: $condition) {
      id
      parent
      creator
      name
      instrument
      category
      description
      tutorialVideos
      externalVideos
      supportingDocuments
      instances {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isDeleted
      isShared
      history
      createdAt
      updatedAt
    }
  }
`;
export const updateSkillTemplate = /* GraphQL */ `
  mutation UpdateSkillTemplate(
    $input: UpdateSkillTemplateInput!
    $condition: ModelSkillTemplateConditionInput
  ) {
    updateSkillTemplate(input: $input, condition: $condition) {
      id
      parent
      creator
      name
      instrument
      category
      description
      tutorialVideos
      externalVideos
      supportingDocuments
      instances {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isDeleted
      isShared
      history
      createdAt
      updatedAt
    }
  }
`;
export const deleteSkillTemplate = /* GraphQL */ `
  mutation DeleteSkillTemplate(
    $input: DeleteSkillTemplateInput!
    $condition: ModelSkillTemplateConditionInput
  ) {
    deleteSkillTemplate(input: $input, condition: $condition) {
      id
      parent
      creator
      name
      instrument
      category
      description
      tutorialVideos
      externalVideos
      supportingDocuments
      instances {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isDeleted
      isShared
      history
      createdAt
      updatedAt
    }
  }
`;
export const createSkillInstance = /* GraphQL */ `
  mutation CreateSkillInstance(
    $input: CreateSkillInstanceInput!
    $condition: ModelSkillInstanceConditionInput
  ) {
    createSkillInstance(input: $input, condition: $condition) {
      id
      owner
      profileID
      templateID
      template {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      createdAt
      percentage
      points
      isDeleted
      isInactive
      isCompleted
      personalTutorialVideos
      responseVideos
      notes
      history
      updatedAt
    }
  }
`;
export const updateSkillInstance = /* GraphQL */ `
  mutation UpdateSkillInstance(
    $input: UpdateSkillInstanceInput!
    $condition: ModelSkillInstanceConditionInput
  ) {
    updateSkillInstance(input: $input, condition: $condition) {
      id
      owner
      profileID
      templateID
      template {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      createdAt
      percentage
      points
      isDeleted
      isInactive
      isCompleted
      personalTutorialVideos
      responseVideos
      notes
      history
      updatedAt
    }
  }
`;
export const deleteSkillInstance = /* GraphQL */ `
  mutation DeleteSkillInstance(
    $input: DeleteSkillInstanceInput!
    $condition: ModelSkillInstanceConditionInput
  ) {
    deleteSkillInstance(input: $input, condition: $condition) {
      id
      owner
      profileID
      templateID
      template {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      createdAt
      percentage
      points
      isDeleted
      isInactive
      isCompleted
      personalTutorialVideos
      responseVideos
      notes
      history
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      owner
      sub
      email
      name
      picture
      roles
      phone
      profiles {
        items {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        nextToken
      }
      isActive
      isDeleted
      org
      createdAt
      updatedAt
      sourcedFrom
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      owner
      sub
      email
      name
      picture
      roles
      phone
      profiles {
        items {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        nextToken
      }
      isActive
      isDeleted
      org
      createdAt
      updatedAt
      sourcedFrom
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      owner
      sub
      email
      name
      picture
      roles
      phone
      profiles {
        items {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        nextToken
      }
      isActive
      isDeleted
      org
      createdAt
      updatedAt
      sourcedFrom
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      owner
      accountID
      account {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      name
      picture
      color
      instrument
      skills {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isActive
      schedule
      location
      billingPlan
      isDeleted
      info
      termsAccepted
      termsDocument
      watches {
        items {
          id
          watcherID
          watcherProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          targetID
          targetProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          lastChat
          lastSkill
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      billCustomerProfileId
      billProrateState
      billRegistrationFeeState
      billPackageTag
      billReferrer
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      owner
      accountID
      account {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      name
      picture
      color
      instrument
      skills {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isActive
      schedule
      location
      billingPlan
      isDeleted
      info
      termsAccepted
      termsDocument
      watches {
        items {
          id
          watcherID
          watcherProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          targetID
          targetProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          lastChat
          lastSkill
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      billCustomerProfileId
      billProrateState
      billRegistrationFeeState
      billPackageTag
      billReferrer
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      owner
      accountID
      account {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      name
      picture
      color
      instrument
      skills {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isActive
      schedule
      location
      billingPlan
      isDeleted
      info
      termsAccepted
      termsDocument
      watches {
        items {
          id
          watcherID
          watcherProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          targetID
          targetProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          lastChat
          lastSkill
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      billCustomerProfileId
      billProrateState
      billRegistrationFeeState
      billPackageTag
      billReferrer
    }
  }
`;
export const createProfileWatch = /* GraphQL */ `
  mutation CreateProfileWatch(
    $input: CreateProfileWatchInput!
    $condition: ModelProfileWatchConditionInput
  ) {
    createProfileWatch(input: $input, condition: $condition) {
      id
      watcherID
      watcherProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      targetID
      targetProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      lastChat
      lastSkill
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfileWatch = /* GraphQL */ `
  mutation UpdateProfileWatch(
    $input: UpdateProfileWatchInput!
    $condition: ModelProfileWatchConditionInput
  ) {
    updateProfileWatch(input: $input, condition: $condition) {
      id
      watcherID
      watcherProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      targetID
      targetProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      lastChat
      lastSkill
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfileWatch = /* GraphQL */ `
  mutation DeleteProfileWatch(
    $input: DeleteProfileWatchInput!
    $condition: ModelProfileWatchConditionInput
  ) {
    deleteProfileWatch(input: $input, condition: $condition) {
      id
      watcherID
      watcherProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      targetID
      targetProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      lastChat
      lastSkill
      createdAt
      updatedAt
      owner
    }
  }
`;
