import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import {
  SkillInstance,
  CreateSkillInstanceMutation,
  UpdateSkillInstanceMutation,
  DeleteSkillInstanceMutation,
  SkillInstancesByProfileQuery,
  SkillTemplate,
} from "../../src/API";

import { skillInstancesByProfileShallow } from "../../queries/skills";
import * as mutations from "../../src/graphql/mutations";
import { useProfile } from "../profile";

type SkillInstanceContextType = {
  skillInstancesForView: SkillInstance[];
  refreshSkillInstancesForView: () => Promise<void>;
  upsertSkillInstance: (
    skillInstance: Partial<SkillInstance>,
    del?: boolean
  ) => Promise<SkillInstance>;
  assignSkill: (
    template: Partial<SkillTemplate>,
    notifier?: any
  ) => Promise<void>;
  updateScore: (
    skill: Partial<SkillInstance>,
    newScore: number | null
  ) => Promise<SkillInstance>;
  toggleActive: (skill: Partial<SkillInstance>) => Promise<SkillInstance>;
  toggleComplete: (skill: Partial<SkillInstance>) => Promise<SkillInstance>;
  toggleDelete: (skill: Partial<SkillInstance>) => Promise<SkillInstance>;
};

const SkillInstanceContext = createContext<
  SkillInstanceContextType | undefined
>(undefined);
export const useSkillInstances = () =>
  useContext(SkillInstanceContext) as SkillInstanceContextType;

export const SkillInstanceProvider = ({ children }: { children: any }) => {
  const [skillInstancesForView, setSkillInstancesForView] = useState<
    SkillInstance[]
  >([]);

  const { getAccessTokenSilently } = useAuth0();
  const { viewProfile, refreshView } = useProfile();

  const refreshSkillInstancesForView = useCallback(async () => {
    if (!viewProfile) return;
    const token = await getAccessTokenSilently();

    const results: any[] = [];
    let nextToken = "";
    do {
      const vars =
        nextToken === "" ? { limit: 200 } : { limit: 200, nextToken };
      const gqlListSkills = (await API.graphql(
        graphqlOperation(
          skillInstancesByProfileShallow,
          { ...vars, profileID: viewProfile.id },
          token
        )
      )) as GraphQLResult<SkillInstancesByProfileQuery>;
      const resultSet =
        gqlListSkills?.data?.skillInstancesByProfile?.items || [];
      results.push(...resultSet);
      nextToken = gqlListSkills?.data?.skillInstancesByProfile?.nextToken || "";
    } while (nextToken !== "");
    setSkillInstancesForView(results as SkillInstance[]);
  }, [getAccessTokenSilently, viewProfile]);

  const upsertSkillInstance = useCallback(
    async (instance: Partial<SkillInstance>, del = false) => {
      const token = await getAccessTokenSilently();

      // clone before mutation
      instance = { ...instance };
      // console.log(instance);

      // remove derived fields for upsert?
      if (instance.template) delete instance.template;
      if (instance.createdAt) delete instance.createdAt;
      if (instance.updatedAt) delete instance.updatedAt;

      if (del) {
        const gqlDeleteSkillInstance = (await API.graphql(
          graphqlOperation(
            mutations.deleteSkillInstance,
            { input: instance },
            token
          )
        )) as GraphQLResult<DeleteSkillInstanceMutation>;
        return gqlDeleteSkillInstance?.data
          ?.deleteSkillInstance as SkillInstance;
      }

      if (instance.id) {
        const gqlUpdateSkillInstance = (await API.graphql(
          graphqlOperation(
            mutations.updateSkillInstance,
            { input: instance },
            token
          )
        )) as GraphQLResult<UpdateSkillInstanceMutation>;
        return gqlUpdateSkillInstance?.data
          ?.updateSkillInstance as SkillInstance;
      }

      const gqlCreateSkillInstance = (await API.graphql(
        graphqlOperation(
          mutations.createSkillInstance,
          { input: instance },
          token
        )
      )) as GraphQLResult<CreateSkillInstanceMutation>;
      return gqlCreateSkillInstance?.data?.createSkillInstance as SkillInstance;
    },
    [getAccessTokenSilently]
  );

  const assignSkill = useCallback(
    async (template: Partial<SkillTemplate>, notifier?: any) => {
      if (!viewProfile) return;

      const sk = await upsertSkillInstance({
        isCompleted: false,
        isDeleted: false,
        isInactive: false,
        owner: viewProfile.account?.sub,
        percentage: 0,
        points: 0,
        profileID: viewProfile.id,
        templateID: template.id,
      });

      if (sk && notifier) {
        notifier(
          `Skill: ${template.name} assigned to profile: ${viewProfile.name}`,
          { variant: "success" }
        );
      }
    },
    [viewProfile, upsertSkillInstance]
  );

  const updateScore = useCallback(
    async (instance: Partial<SkillInstance>, newScore: number | null) => {
      instance.percentage = (newScore || 0) * 20;
      return upsertSkillInstance({
        id: instance.id,
        percentage: instance.percentage,
      });
    },
    [upsertSkillInstance]
  );

  const toggleDelete = useCallback(
    async (instance: Partial<SkillInstance>) => {
      instance.isDeleted = !instance.isDeleted;
      return upsertSkillInstance({
        id: instance.id,
        isDeleted: instance.isDeleted,
      });
    },
    [upsertSkillInstance]
  );

  const toggleActive = useCallback(
    async (instance: Partial<SkillInstance>) => {
      instance.isInactive = !instance.isInactive;
      return upsertSkillInstance({
        id: instance.id,
        isInactive: instance.isInactive,
      });
    },
    [upsertSkillInstance]
  );

  const toggleComplete = useCallback(
    async (instance: Partial<SkillInstance>) => {
      instance.isCompleted = !instance.isCompleted;
      return upsertSkillInstance({
        id: instance.id,
        isCompleted: instance.isCompleted,
      });
    },
    [upsertSkillInstance]
  );

  const value = useMemo(
    () => ({
      skillInstancesForView,
      refreshSkillInstancesForView,
      upsertSkillInstance,
      assignSkill,
      updateScore,
      toggleActive,
      toggleComplete,
      toggleDelete,
    }),
    [
      assignSkill,
      refreshSkillInstancesForView,
      skillInstancesForView,
      toggleActive,
      toggleComplete,
      toggleDelete,
      updateScore,
      upsertSkillInstance,
    ]
  );

  return (
    <SkillInstanceContext.Provider value={value}>
      {children}
    </SkillInstanceContext.Provider>
  );
};
