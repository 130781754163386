import { Callback } from "../../../../types/twilio";
import { isMobile } from "../../../../utilities/video/videoDefaults";
import Video, { LocalTrack, Room } from "twilio-video";
import { useCallback, useRef, useState } from "react";
import useConnectionOptions from "../../../../utilities/video/useConnectionOptions";

// @ts-ignore
if (typeof window !== "undefined") {
  // @ts-ignore
  window.TwilioVideo = Video;
}

export default function useRoom(localTracks: LocalTrack[], onError: Callback) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const connectionOptions = useConnectionOptions();
  const optionsRef = useRef(connectionOptions);

  const connect = useCallback(
    (token: any) => {
      setIsConnecting(true);
      return Video.connect(token, {
        ...optionsRef.current,
        tracks: localTracks,
      }).then(
        (newRoom) => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          // Adding 13 or more 'participantDisconnected' listeners to the room object can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          // this should be a rare, if ever, occurrence since most use of rooms will be peer-to-peer
          newRoom.setMaxListeners(15);

          newRoom.once("disconnected", () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener("beforeunload", disconnect);

            if (isMobile) {
              window.removeEventListener("pagehide", disconnect);
            }
          });

          // @ts-ignore
          if (typeof window !== undefined) {
            // @ts-ignore
            window.twilioRoom = newRoom;
          }

          newRoom.localParticipant.videoTracks.forEach((publication) =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority("low")
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener("beforeunload", disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener("pagehide", disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError, setIsConnecting, setRoom]
  );

  return { room, isConnecting, connect };
}
