import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Video, { CreateLocalTrackOptions, ConnectOptions, LocalAudioTrack, LocalVideoTrack, Room } from 'twilio-video';
import { ErrorCallback } from '../types/twilio';
import { SelectedParticipantProvider } from '../components/twilio/VideoProvider/useSelectedParticipant/useSelectedParticipant';

import AttachVisibilityHandler from '../components/twilio/VideoProvider/AttachVisibilityHandler/AttachVisibilityHandler';
import useHandleRoomDisconnection from '../components/twilio/VideoProvider/useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from '../components/twilio/VideoProvider/useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from '../components/twilio/VideoProvider/useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from '../components/twilio/VideoProvider/useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from '../components/twilio/VideoProvider/useRoom/useRoom';
import useScreenShareToggle from '../components/twilio/VideoProvider/useScreenShareToggle/useScreenShareToggle';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

type VideoContextType = {
    room: Room | null;
    localTracks: (LocalAudioTrack | LocalVideoTrack)[];
    isConnecting: boolean;
    connect: (token: string) => Promise<void>;
    onError: ErrorCallback;
    getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
    getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
    isAcquiringLocalTracks: boolean;
    removeLocalVideoTrack: () => void;
    isSharingScreen: boolean;
    toggleScreenShare: () => void;
    getAudioAndVideoTracks: () => Promise<void>;
    fullScreenMode: boolean;
    setFullScreenMode(m: boolean): void;
};

const VideoContext = createContext<VideoContextType | undefined>(undefined);
export const useVideoContext = () => useContext(VideoContext) as VideoContextType;

interface VideoProviderProps {
    options?: ConnectOptions;
    children: ReactNode;
}

export const VideoProvider = ({ options, children }: VideoProviderProps) => {
    const onError = (e: Video.TwilioError | Error) => {};
    const onErrorCallback: ErrorCallback = useCallback(
        (error) => {
            console.error(`ERROR: ${error.message}`, error);
            onError(error);
        },
        [onError]
    );

    const {
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks
    } = useLocalTracks();

    const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback);
    const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

    // Register callback functions to be called on room disconnect.
    useHandleRoomDisconnection(room, onError, removeLocalAudioTrack, removeLocalVideoTrack, isSharingScreen, toggleScreenShare);
    useHandleTrackPublicationFailed(room, onError);
    useRestartAudioTrackOnDeviceChange(localTracks);

    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

    const contextValue = {
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
        fullScreenMode,
        setFullScreenMode
    };

    return (
        <VideoContext.Provider value={contextValue}>
            <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
            {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
            <AttachVisibilityHandler />
        </VideoContext.Provider>
    );
};
