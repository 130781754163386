import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import {
  Profile,
  ListProfilesQuery,
  ProfileWatch,
  CreateProfileWatchMutation,
  DeleteProfileWatchMutation,
} from "../../src/API";

import * as mutations from "../../src/graphql/mutations";
import { useProfile } from "../profile";

import useSWR from "swr";
import { idFetcher } from "../../utilities/fetcher";
import { listProfilesForManifest } from "../../queries/skills";
import { useAWS } from "../federatedSignIn";

import axios from "axios";
import { encode, decode } from "@msgpack/msgpack";

type StudentContextType = {
  students: Profile[];
  refreshStudents: () => void;
  toggleWatch: (s: Profile) => Promise<void>;
  rebuildProfileManifest: () => Promise<void>;
  isLoading: boolean;
};

const StudentContext = createContext<StudentContextType | undefined>(undefined);
export const useStudents = () =>
  useContext(StudentContext) as StudentContextType;

export const PROFILE_MANIFEST_NAME = "profiles";
const allowedInProfileManifest = [
  "id",
  "accountID",
  "name",
  "instrument",
  "isActive",
  "location",
  "billingPlan",
  "isDeleted",
  "termsAccepted",
  "createdAt",
  "updatedAt",
  "account",
];
const allowedInAccountManifest = ["id", "email", "name", "isActive", "roles"];

export const getProfileManifest = (profile: Partial<Profile>) => {
  const profileManifest = Object.keys(profile)
    .filter((key) => allowedInProfileManifest.includes(key))
    .reduce((obj: any, key) => {
      obj[key] = (profile as any)[key];
      return obj;
    }, {});

  const account = profileManifest.account;
  if (account) {
    profileManifest.account = Object.keys(account)
      .filter((key) => allowedInAccountManifest.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = (account as any)[key];
        return obj;
      }, {});
  }

  return profileManifest;
};

export const StudentProvider = ({ children }: { children: any }) => {
  const [students, setStudents] = useState<Profile[]>([]);

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { account, activeProfile, refresh } = useProfile();
  const { awsReady } = useAWS();

  const {
    data: profiles,
    isValidating,
    mutate,
  } = useSWR(
    account?.roles?.some((r) =>
      ["teacher", "admin", "superadmin"].includes(r as string)
    )
      ? `/api/manifest/get/${PROFILE_MANIFEST_NAME}`
      : null,
    (url) =>
      idFetcher(url, getIdTokenClaims)
        .then((res) => fetch(res.signedUrl))
        .then((res) => res.arrayBuffer())
        .then((res) => decode(res) as Profile[])
  );

  useEffect(() => {
    if (!profiles) return;

    setStudents(
      profiles?.length
        ? profiles?.filter(
            (p: any) =>
              p.account?.roles === undefined ||
              p.account?.roles?.length === 0 ||
              !p.account?.roles?.some((r: any) =>
                ["teacher", "admin", "superadmin"].includes(r as string)
              )
          )
        : []
    );
  }, [profiles]);

  const rebuildManifest = useCallback(async () => {
    if (!awsReady) return;
    const token = await getAccessTokenSilently();
    const results: any[] = [];
    let nextToken = "";
    do {
      const vars =
        nextToken === "" ? { limit: 200 } : { limit: 200, nextToken };
      const result = (await API.graphql(
        graphqlOperation(listProfilesForManifest, vars, token)
      )) as GraphQLResult<ListProfilesQuery>;
      const resultSet = result?.data?.listProfiles?.items || [];
      results.push(...resultSet);
      nextToken = result?.data?.listProfiles?.nextToken || "";
    } while (nextToken !== "");

    const blob = new Blob([encode(results)]);

    try {
      const manifestSetResult = await idFetcher(
        `/api/manifest/set/${PROFILE_MANIFEST_NAME}`,
        getIdTokenClaims
      );
      await axios.put(manifestSetResult.signedUrl, blob, {
        headers: { "content-type": blob.type },
      });
      mutate();
    } catch (error) {
      console.error("error", error);
    }
  }, [getAccessTokenSilently, getIdTokenClaims, awsReady, mutate]);

  const toggleWatch = useCallback(
    async (student: Profile) => {
      if (!activeProfile) return;

      const found = activeProfile.watches?.items?.find(
        (w) => w?.targetID === student?.id
      );

      const token = await getAccessTokenSilently();
      if (found) {
        const watchDetails: Partial<ProfileWatch> = { id: found.id };
        const gqlDeleteWatch = (await API.graphql(
          graphqlOperation(
            mutations.deleteProfileWatch,
            { input: watchDetails },
            token
          )
        )) as GraphQLResult<DeleteProfileWatchMutation>;
      } else {
        const watchDetails: Partial<ProfileWatch> = {
          watcherID: activeProfile.id,
          targetID: student?.id,
          lastChat: new Date(0).toISOString(),
          lastSkill: new Date(0).toISOString(),
        };
        const gqlCreateWatch = (await API.graphql(
          graphqlOperation(
            mutations.createProfileWatch,
            { input: watchDetails },
            token
          )
        )) as GraphQLResult<CreateProfileWatchMutation>;
      }

      refresh();
    },
    [activeProfile, getAccessTokenSilently, refresh]
  );

  const value = useMemo(
    () => ({
      students,
      refreshStudents: mutate,
      toggleWatch,
      isLoading: isValidating,
      rebuildProfileManifest: rebuildManifest,
    }),
    [isValidating, mutate, rebuildManifest, students, toggleWatch]
  );

  return (
    <StudentContext.Provider value={value}>{children}</StudentContext.Provider>
  );
};
