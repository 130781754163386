// React
import { useEffect } from "react";

// Next.js
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";

// Amplify
import config from "../src/aws-extensions";
import { Amplify } from "@aws-amplify/core";
Amplify.configure({
  ...config,
  ssr: true,
  mandatorySignIn: true,
});

// LogRocket
import LogRocket from "logrocket";
if (process.env.NEXT_PUBLIC_LOGROCKET_ENABLED === "true") {
  LogRocket.init("real-brave/studiorealbraveaudiocom");
}

// Auth0
import { Auth0Provider } from "@auth0/auth0-react";

// MUI
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY || "");
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

// Providers
import { FederatedSignIn } from "../providers/federatedSignIn";
import { ProfileProvider } from "../providers/profile";
import { PlatformSettingsProvider } from "../providers/platformSettings";
import { VideoStateProvider } from "../providers/twilio";
import { VideoProvider } from "../providers/video";
import { SkillTemplateProvider } from "../providers/state/skillTemplate";
import { SkillInstanceProvider } from "../providers/state/skillInstance";
import { StudentProvider } from "../providers/state/student";
import { Reports } from "../providers/reports";

// Styles
import "../styles/globals.css";
import theme from "../styles/theme";

import Compose from "../utilities/compose";
// import watchDataScroll from "../utilities/scroll";

function RealBraveStudio({ Component, pageProps }: AppProps) {
  // useEffect(() => watchDataScroll(), []);

  // Google Analytics routing events
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      (window as any).gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // Use the layout defined at the page level, if available
  const getLayout = (Component as any).getLayout || ((page: any) => page);

  const components: [
    React.JSXElementConstructor<React.PropsWithChildren<any>>,
    any
  ][] = [
    [LocalizationProvider, { dateAdapter: AdapterMoment }],
    [
      Auth0Provider,
      {
        domain: config.auth0Domain,
        clientId: config.auth0ClientId,
        redirectUri: `${process.env.NEXT_PUBLIC_BASE_URL}/authorize`,
        cacheLocation: "localstorage",
        useRefreshTokens: true,
        scope: "openid profile email offline_access",
        audience: config.auth0ApiAudience,
      },
    ],
    [FederatedSignIn, {}],
    [PlatformSettingsProvider, {}],
    [ProfileProvider, {}],
    [StudentProvider, {}],
    [SkillTemplateProvider, {}],
    [SkillInstanceProvider, {}],
    [VideoStateProvider, {}],
    [VideoProvider, {}],
    [Reports, {}],
    [ThemeProvider, { theme }],
    [SnackbarProvider, { maxSnack: 3 }],
  ];

  return (
    <>
      <Compose components={components}>
        {getLayout(<Component {...pageProps} />)}
      </Compose>
      <Script
        id="fb-view"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_ID}}');
            fbq('track', 'PageView');
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
}

export default RealBraveStudio;
