/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      ownerAccounts
      topic
      messages {
        items {
          id
          ownerAccounts
          chatID
          createdAt
          profile
          body
          attachments {
            items {
              id
              ownerAccounts
              messageID
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerAccounts
        topic
        messages {
          items {
            id
            ownerAccounts
            chatID
            createdAt
            profile
            body
            attachments {
              nextToken
            }
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatsByTopic = /* GraphQL */ `
  query ChatsByTopic(
    $topic: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatsByTopic(
      topic: $topic
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerAccounts
        topic
        messages {
          items {
            id
            ownerAccounts
            chatID
            createdAt
            profile
            body
            attachments {
              nextToken
            }
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      ownerAccounts
      chatID
      createdAt
      profile
      body
      attachments {
        items {
          id
          ownerAccounts
          messageID
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerAccounts
        chatID
        createdAt
        profile
        body
        attachments {
          items {
            id
            ownerAccounts
            messageID
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByChat = /* GraphQL */ `
  query MessagesByChat(
    $chatID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChat(
      chatID: $chatID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerAccounts
        chatID
        createdAt
        profile
        body
        attachments {
          items {
            id
            ownerAccounts
            messageID
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatAttachment = /* GraphQL */ `
  query GetChatAttachment($id: ID!) {
    getChatAttachment(id: $id) {
      id
      ownerAccounts
      messageID
      body
      createdAt
      updatedAt
    }
  }
`;
export const listChatAttachments = /* GraphQL */ `
  query ListChatAttachments(
    $filter: ModelChatAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerAccounts
        messageID
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatAttachmentsByMessage = /* GraphQL */ `
  query ChatAttachmentsByMessage(
    $messageID: ID!
    $body: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatAttachmentsByMessage(
      messageID: $messageID
      body: $body
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerAccounts
        messageID
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      tag
      label
      address
      phone
      calendarsByInstrument
      consultCalendar
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag
        label
        address
        phone
        calendarsByInstrument
        consultCalendar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInstrument = /* GraphQL */ `
  query GetInstrument($id: ID!) {
    getInstrument(id: $id) {
      id
      tag
      label
      createdAt
      updatedAt
    }
  }
`;
export const listInstruments = /* GraphQL */ `
  query ListInstruments(
    $filter: ModelInstrumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstruments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const settingsByName = /* GraphQL */ `
  query SettingsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkillTemplate = /* GraphQL */ `
  query GetSkillTemplate($id: ID!) {
    getSkillTemplate(id: $id) {
      id
      parent
      creator
      name
      instrument
      category
      description
      tutorialVideos
      externalVideos
      supportingDocuments
      instances {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isDeleted
      isShared
      history
      createdAt
      updatedAt
    }
  }
`;
export const listSkillTemplates = /* GraphQL */ `
  query ListSkillTemplates(
    $filter: ModelSkillTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const skillTemplatesByCreator = /* GraphQL */ `
  query SkillTemplatesByCreator(
    $creator: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSkillTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillTemplatesByCreator(
      creator: $creator
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const skillTemplatesByName = /* GraphQL */ `
  query SkillTemplatesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSkillTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillTemplatesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkillInstance = /* GraphQL */ `
  query GetSkillInstance($id: ID!) {
    getSkillInstance(id: $id) {
      id
      owner
      profileID
      templateID
      template {
        id
        parent
        creator
        name
        instrument
        category
        description
        tutorialVideos
        externalVideos
        supportingDocuments
        instances {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isDeleted
        isShared
        history
        createdAt
        updatedAt
      }
      createdAt
      percentage
      points
      isDeleted
      isInactive
      isCompleted
      personalTutorialVideos
      responseVideos
      notes
      history
      updatedAt
    }
  }
`;
export const listSkillInstances = /* GraphQL */ `
  query ListSkillInstances(
    $filter: ModelSkillInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        profileID
        templateID
        template {
          id
          parent
          creator
          name
          instrument
          category
          description
          tutorialVideos
          externalVideos
          supportingDocuments
          instances {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isDeleted
          isShared
          history
          createdAt
          updatedAt
        }
        createdAt
        percentage
        points
        isDeleted
        isInactive
        isCompleted
        personalTutorialVideos
        responseVideos
        notes
        history
        updatedAt
      }
      nextToken
    }
  }
`;
export const skillInstancesByProfile = /* GraphQL */ `
  query SkillInstancesByProfile(
    $profileID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillInstancesByProfile(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        profileID
        templateID
        template {
          id
          parent
          creator
          name
          instrument
          category
          description
          tutorialVideos
          externalVideos
          supportingDocuments
          instances {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isDeleted
          isShared
          history
          createdAt
          updatedAt
        }
        createdAt
        percentage
        points
        isDeleted
        isInactive
        isCompleted
        personalTutorialVideos
        responseVideos
        notes
        history
        updatedAt
      }
      nextToken
    }
  }
`;
export const skillInstancesByTemplate = /* GraphQL */ `
  query SkillInstancesByTemplate(
    $templateID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillInstancesByTemplate(
      templateID: $templateID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        profileID
        templateID
        template {
          id
          parent
          creator
          name
          instrument
          category
          description
          tutorialVideos
          externalVideos
          supportingDocuments
          instances {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isDeleted
          isShared
          history
          createdAt
          updatedAt
        }
        createdAt
        percentage
        points
        isDeleted
        isInactive
        isCompleted
        personalTutorialVideos
        responseVideos
        notes
        history
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      owner
      sub
      email
      name
      picture
      roles
      phone
      profiles {
        items {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        nextToken
      }
      isActive
      isDeleted
      org
      createdAt
      updatedAt
      sourcedFrom
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      nextToken
    }
  }
`;
export const accountsBySub = /* GraphQL */ `
  query AccountsBySub(
    $sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      nextToken
    }
  }
`;
export const accountsByEmail = /* GraphQL */ `
  query AccountsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      nextToken
    }
  }
`;
export const accountsBySourcedFrom = /* GraphQL */ `
  query AccountsBySourcedFrom(
    $sourcedFrom: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsBySourcedFrom(
      sourcedFrom: $sourcedFrom
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      owner
      accountID
      account {
        id
        owner
        sub
        email
        name
        picture
        roles
        phone
        profiles {
          items {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          nextToken
        }
        isActive
        isDeleted
        org
        createdAt
        updatedAt
        sourcedFrom
      }
      name
      picture
      color
      instrument
      skills {
        items {
          id
          owner
          profileID
          templateID
          template {
            id
            parent
            creator
            name
            instrument
            category
            description
            tutorialVideos
            externalVideos
            supportingDocuments
            instances {
              nextToken
            }
            isDeleted
            isShared
            history
            createdAt
            updatedAt
          }
          createdAt
          percentage
          points
          isDeleted
          isInactive
          isCompleted
          personalTutorialVideos
          responseVideos
          notes
          history
          updatedAt
        }
        nextToken
      }
      isActive
      schedule
      location
      billingPlan
      isDeleted
      info
      termsAccepted
      termsDocument
      watches {
        items {
          id
          watcherID
          watcherProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          targetID
          targetProfile {
            id
            owner
            accountID
            account {
              id
              owner
              sub
              email
              name
              picture
              roles
              phone
              isActive
              isDeleted
              org
              createdAt
              updatedAt
              sourcedFrom
            }
            name
            picture
            color
            instrument
            skills {
              nextToken
            }
            isActive
            schedule
            location
            billingPlan
            isDeleted
            info
            termsAccepted
            termsDocument
            watches {
              nextToken
            }
            createdAt
            updatedAt
            billCustomerProfileId
            billProrateState
            billRegistrationFeeState
            billPackageTag
            billReferrer
          }
          lastChat
          lastSkill
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      billCustomerProfileId
      billProrateState
      billRegistrationFeeState
      billPackageTag
      billReferrer
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      nextToken
    }
  }
`;
export const profilesByAccount = /* GraphQL */ `
  query ProfilesByAccount(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByAccount(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      nextToken
    }
  }
`;
export const profilesByBillProrateState = /* GraphQL */ `
  query ProfilesByBillProrateState(
    $billProrateState: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByBillProrateState(
      billProrateState: $billProrateState
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      nextToken
    }
  }
`;
export const profilesByBillRegistrationFeeState = /* GraphQL */ `
  query ProfilesByBillRegistrationFeeState(
    $billRegistrationFeeState: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByBillRegistrationFeeState(
      billRegistrationFeeState: $billRegistrationFeeState
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      nextToken
    }
  }
`;
export const profilesByBillPackageTag = /* GraphQL */ `
  query ProfilesByBillPackageTag(
    $billPackageTag: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByBillPackageTag(
      billPackageTag: $billPackageTag
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      nextToken
    }
  }
`;
export const getProfileWatch = /* GraphQL */ `
  query GetProfileWatch($id: ID!) {
    getProfileWatch(id: $id) {
      id
      watcherID
      watcherProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      targetID
      targetProfile {
        id
        owner
        accountID
        account {
          id
          owner
          sub
          email
          name
          picture
          roles
          phone
          profiles {
            items {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            nextToken
          }
          isActive
          isDeleted
          org
          createdAt
          updatedAt
          sourcedFrom
        }
        name
        picture
        color
        instrument
        skills {
          items {
            id
            owner
            profileID
            templateID
            template {
              id
              parent
              creator
              name
              instrument
              category
              description
              tutorialVideos
              externalVideos
              supportingDocuments
              isDeleted
              isShared
              history
              createdAt
              updatedAt
            }
            createdAt
            percentage
            points
            isDeleted
            isInactive
            isCompleted
            personalTutorialVideos
            responseVideos
            notes
            history
            updatedAt
          }
          nextToken
        }
        isActive
        schedule
        location
        billingPlan
        isDeleted
        info
        termsAccepted
        termsDocument
        watches {
          items {
            id
            watcherID
            watcherProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            targetID
            targetProfile {
              id
              owner
              accountID
              name
              picture
              color
              instrument
              isActive
              schedule
              location
              billingPlan
              isDeleted
              info
              termsAccepted
              termsDocument
              createdAt
              updatedAt
              billCustomerProfileId
              billProrateState
              billRegistrationFeeState
              billPackageTag
              billReferrer
            }
            lastChat
            lastSkill
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        billCustomerProfileId
        billProrateState
        billRegistrationFeeState
        billPackageTag
        billReferrer
      }
      lastChat
      lastSkill
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfileWatches = /* GraphQL */ `
  query ListProfileWatches(
    $filter: ModelProfileWatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileWatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        watcherID
        watcherProfile {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        targetID
        targetProfile {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        lastChat
        lastSkill
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const watchesByWatcher = /* GraphQL */ `
  query WatchesByWatcher(
    $watcherID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileWatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchesByWatcher(
      watcherID: $watcherID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        watcherID
        watcherProfile {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        targetID
        targetProfile {
          id
          owner
          accountID
          account {
            id
            owner
            sub
            email
            name
            picture
            roles
            phone
            profiles {
              nextToken
            }
            isActive
            isDeleted
            org
            createdAt
            updatedAt
            sourcedFrom
          }
          name
          picture
          color
          instrument
          skills {
            items {
              id
              owner
              profileID
              templateID
              createdAt
              percentage
              points
              isDeleted
              isInactive
              isCompleted
              personalTutorialVideos
              responseVideos
              notes
              history
              updatedAt
            }
            nextToken
          }
          isActive
          schedule
          location
          billingPlan
          isDeleted
          info
          termsAccepted
          termsDocument
          watches {
            items {
              id
              watcherID
              targetID
              lastChat
              lastSkill
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          billCustomerProfileId
          billProrateState
          billRegistrationFeeState
          billPackageTag
          billReferrer
        }
        lastChat
        lastSkill
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
