import { createContext, useContext, useMemo, useState } from "react";

import { Moment } from "moment-timezone";

interface ReportContext {
  begin: Moment | undefined;
  end: Moment | undefined;
  rows: any[];
}

type ReportsContextType = {
  pendingBilling: any[];
  setPendingBilling: (pendingBilling: any[]) => void;
  pPadScore: ReportContext;
  setPPadScore: (pPadScore: ReportContext) => void;
};

const ReportsContext = createContext<ReportsContextType | undefined>(undefined);
export const useReports = () =>
  useContext(ReportsContext) as ReportsContextType;

export const Reports = ({ children }: { children: any }) => {
  const [pendingBilling, setPendingBilling] = useState<any[]>([]);
  const [pPadScore, setPPadScore] = useState<ReportContext>({
    begin: undefined,
    end: undefined,
    rows: [],
  });

  const value = useMemo(
    () => ({
      pendingBilling,
      setPendingBilling,
      pPadScore,
      setPPadScore,
    }),
    [pPadScore, pendingBilling]
  );

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};
