import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import config from "../src/aws-extensions";
import { useAuth0 } from "@auth0/auth0-react";

type AWSContextType = {
  awsReady: boolean;
};

const AWSContext = createContext<AWSContextType | undefined>(undefined);
export const useAWS = () => useContext(AWSContext) as AWSContextType;

export const FederatedSignIn = ({ children }: { children: any }) => {
  const [awsReady, setAwsReady] = useState(false);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const refresh = async () => {
          const idToken: any = await getIdTokenClaims();
          return {
            token: idToken.__raw,
            expires_at: idToken.exp! * 1000,
          };
        };
        const refreshHandlers: any = {};
        refreshHandlers[config.auth0Domain] = refresh;
        Amplify.configure({
          refreshHandlers,
          /*
           * Example of API with custom headers, which could be used to pass a self-managed Bearer token
           *
           * API: {
           *   graphql_headers: async () => ({
           *     "My-Custom-Header": "my value",
           *   }),
           * },
           *
           */
        });

        try {
          await Auth.currentAuthenticatedUser();
        } catch {
          const idToken: any = await getIdTokenClaims();
          await Auth.federatedSignIn(
            config.auth0Domain,
            {
              token: idToken.__raw,
              expires_at: idToken.exp! * 1000,
            },
            {
              name: idToken.name! || idToken.email!,
              email: idToken.email,
              picture: idToken.picture,
            }
          );
        }

        // if the idtoken is expired, this will trigger a refresh on page (re)load
        try {
          await Auth.currentCredentials();
        } catch {}

        setAwsReady(true);
      }
    })();
  }, [isAuthenticated, getIdTokenClaims]);

  const value = useMemo(() => ({ awsReady }), [awsReady]);

  return <AWSContext.Provider value={value}>{children}</AWSContext.Provider>;
};
