interface ComposeProps {
  components: [
    React.JSXElementConstructor<React.PropsWithChildren<any>>,
    any
  ][];
  children: React.ReactNode;
}

const Compose = (props: ComposeProps) => {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((acc, [Comp, attrs]) => {
        return <Comp {...attrs}>{acc}</Comp>;
      }, children)}
    </>
  );
};

export default Compose;
