import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { useAWS } from "./federatedSignIn";

import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import {
  AccountsBySubQuery,
  CreateAccountMutation,
  GetProfileQuery,
  UpdateAccountMutation,
  UpdateProfileMutation,
  Account,
  Profile,
  GetAccountQuery,
  AccountsByEmailQuery,
  CreateProfileMutation,
} from "../src/API";
import * as queries from "../src/graphql/queries";
import * as mutations from "../src/graphql/mutations";

import config from "../src/aws-extensions";
import { postFetcher } from "../utilities/fetcher";
import { getProfileManifest, PROFILE_MANIFEST_NAME } from "./state/student";
import { Appointment } from "../types/acuity";

import LogRocket from "logrocket";

type ProfileContextType = {
  account: Account | undefined;
  activeProfile: Profile | undefined;
  selectProfile: (p: Profile | undefined) => void;
  viewProfile: Profile | undefined;
  selectViewProfile: (p: Profile | undefined) => Promise<void>;
  refresh: () => Promise<void>;
  refreshView: () => Promise<void>;
  updateAccount: (a: Partial<Account>) => Promise<any>;
  updateProfile: (p: Partial<Profile>, syncManifest?: boolean) => Promise<any>;
  accountSync: (user: Partial<User>) => Promise<Account | undefined>;
  updateLessonStatus: (x: boolean | undefined) => void;
  isInLesson: boolean | undefined;
  updateActiveLesson: (l: number | undefined) => void;
  activeLesson: number | undefined;
  isLoading: boolean | undefined;
  getAccount: (id: string) => Promise<Account>;
  getProfile: (id: string) => Promise<Profile>;
  getProfileFromAppt: (
    appt: Partial<Appointment>
  ) => Promise<Profile | undefined>;
  useRealbraveApp: boolean | undefined;
  updateUseRealBraveApp: (u: boolean | undefined) => void;
  createAuthNetProfileForViewProfile: () => Promise<any>;
};

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);
export const useProfile = () =>
  useContext(ProfileContext) as ProfileContextType;

export const ProfileProvider = ({ children }: { children: any }) => {
  const [account, setAccount] = useState<Account>();
  const [activeProfile, setActiveProfile] = useState<Profile>();
  const [viewProfile, setViewProfile] = useState<Profile>();
  const [isInLesson, setIsInLesson] = useState<boolean>();
  const [activeLesson, setLessonRoom] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [useRealbraveApp, setUseRealbraveApp] = useState<boolean>();

  // sync the auth0 user and the appsync account
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { awsReady } = useAWS();

  useEffect(() => {
    if (!activeProfile) return;
    if (process.env.NEXT_PUBLIC_LOGROCKET_ENABLED === "true") {
      LogRocket.identify(activeProfile.id, {
        name: `${activeProfile.name} ${activeProfile.account?.name}`,
        email: activeProfile.account?.email || "",

        // custom user variables
        phone: activeProfile.account?.phone || "",
        studio: activeProfile.location || "",
        instrument: activeProfile.instrument || "",
      });
    }
  }, [activeProfile]);

  const accountSync = useCallback(
    async (user: Partial<User>) => {
      const token = await getAccessTokenSilently();
      const gqlAccountsBySub = (await API.graphql(
        graphqlOperation(queries.accountsBySub, { sub: user.sub }, token)
      )) as GraphQLResult<AccountsBySubQuery>;
      const dbAccounts = gqlAccountsBySub?.data?.accountsBySub?.items || [];
      const syncAccountDetails: any = {
        sub: user.sub,
        name: user.name,
        email: user.email,
        picture: user.picture,
        roles: user[config.auth0RolesClaim] || [],
      };
      if (user[config.auth0MetaClaim]?.phone) {
        syncAccountDetails.phone = user[config.auth0MetaClaim].phone;
      }
      if (user[config.auth0MetaClaim]?.source) {
        syncAccountDetails.sourcedFrom = user[config.auth0MetaClaim].source;
      }
      let currentAccount: Account | undefined;
      if (dbAccounts.length === 0) {
        const newAccountDetails = {
          ...syncAccountDetails,
          owner: user.sub,
          isActive:
            user.isActive ||
            (syncAccountDetails.roles?.some((r: any) =>
              ["teacher", "admin", "superadmin"].includes(r as string)
            )
              ? true
              : false),
        };
        const gqlCreateAccount = (await API.graphql(
          graphqlOperation(
            mutations.createAccount,
            { input: newAccountDetails },
            token
          )
        )) as GraphQLResult<CreateAccountMutation>;
        const newAccount = gqlCreateAccount?.data?.createAccount;
        if (newAccount) {
          currentAccount = newAccount as any;

          // if the user is new, create a profile from their auth0 user_metadata if possible
          const meta = user[config.auth0MetaClaim];

          const firstName = meta?.firstName || "";
          const instrument = meta?.instrument || "";
          const studio = meta?.studio || "";

          if (firstName && instrument && studio) {
            const newProfileDetails = {
              accountID: newAccount.id,
              name: firstName.trim(),
              instrument,
              isActive: false,
              location: studio,
              owner: user.sub,
            };
            const token = await getAccessTokenSilently();
            const gqlCreateProfile = (await API.graphql(
              graphqlOperation(
                mutations.createProfile,
                { input: newProfileDetails },
                token
              )
            )) as GraphQLResult<CreateProfileMutation>;
            const newProfile = gqlCreateProfile?.data?.createProfile as Profile;

            await postFetcher(
              `/api/manifest/put/${PROFILE_MANIFEST_NAME}`,
              {
                value: getProfileManifest(newProfile),
              },
              getIdTokenClaims
            );

            currentAccount = newProfile.account as any;
          }
        }
      } else {
        const updateAccountDetails = {
          ...syncAccountDetails,
          id: dbAccounts[0]!.id,
        };
        const gqlUpdateAccount = (await API.graphql(
          graphqlOperation(
            mutations.updateAccount,
            { input: updateAccountDetails },
            token
          )
        )) as GraphQLResult<UpdateAccountMutation>;
        const updateAccount = gqlUpdateAccount?.data?.updateAccount;
        if (updateAccount) currentAccount = updateAccount as any;
      }
      if (!currentAccount) {
        console.error(
          "could not sync the auth0 user and the appsync account for user",
          user
        );
        return;
      }
      return currentAccount;
    },
    [getAccessTokenSilently]
  );

  const updateLessonStatus = useCallback((x: boolean | undefined) => {
    if (typeof window !== "undefined") {
      if (x) {
        sessionStorage.setItem("is_in_lesson", "true");
      } else {
        sessionStorage.removeItem("is_in_lesson");
      }
    }
    setIsInLesson(x);
  }, []);

  const updateUseRealBraveApp = useCallback((u: boolean | undefined) => {
    if (typeof window !== "undefined") {
      if (u == undefined) {
        localStorage.removeItem("use_realbrave_app");
      } else {
        let now = new Date();
        now.setDate(now.getDate() + 1 * 7);
        const data = {
          value: u.valueOf(),
          ttl: now,
        };
        localStorage.setItem("use_realbrave_app", JSON.stringify(data));
      }
    }
    setUseRealbraveApp(u);
  }, []);

  const updateActiveLesson = useCallback((l: number | undefined) => {
    if (typeof window !== "undefined") {
      if (l) {
        sessionStorage.setItem("active_lesson", l.toString());
      } else {
        sessionStorage.removeItem("active_lesson");
      }
    }
    setLessonRoom(l);
  }, []);

  const refreshView = useCallback(async () => {
    if (typeof window !== "undefined") {
      setIsLoading(true);

      const lessonStatus = sessionStorage.getItem("is_in_lesson");
      if (lessonStatus) {
        updateLessonStatus(Boolean(JSON.parse(lessonStatus)));
      }
      const activeLessonRoom = sessionStorage.getItem("active_lesson");
      if (activeLessonRoom) {
        updateActiveLesson(Number(activeLessonRoom));
      }

      const useApp = localStorage.getItem("use_realbrave_app");
      if (useApp) {
        const data = JSON.parse(useApp);
        if (new Date().valueOf() > new Date(data.ttl).valueOf()) {
          updateUseRealBraveApp(undefined);
        } else {
          updateUseRealBraveApp(Boolean(data.value));
        }
      }

      const token = await getAccessTokenSilently();
      const viewProfileId = localStorage.getItem("realbrave_view_profile_id");
      if (viewProfileId) {
        const gqlGetProfile = (await API.graphql(
          graphqlOperation(queries.getProfile, { id: viewProfileId }, token)
        )) as GraphQLResult<GetProfileQuery>;
        const result = gqlGetProfile?.data?.getProfile;
        if (result) setViewProfile(result as any);
      }
      setIsLoading(false);
    }
  }, [
    getAccessTokenSilently,
    updateActiveLesson,
    updateLessonStatus,
    updateUseRealBraveApp,
  ]);

  const refresh = useCallback(async () => {
    if (!user || !user.sub || !awsReady) return;

    const currentAccount = await accountSync(user);
    setAccount(currentAccount);

    if (typeof window !== "undefined" && currentAccount) {
      const profileId = localStorage.getItem("realbrave_profile_id");
      const found = currentAccount.profiles?.items?.find(
        (p: any) => p.id === profileId
      );
      if (found) setActiveProfile(found);
    }

    await refreshView();
  }, [user, awsReady, accountSync, refreshView]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const getAccount = useCallback(
    async (id: string) => {
      const token = await getAccessTokenSilently();
      const result = (await API.graphql(
        graphqlOperation(queries.getAccount, { id }, token)
      )) as GraphQLResult<GetAccountQuery>;
      const account = result?.data?.getAccount;
      return account as Account;
    },
    [getAccessTokenSilently]
  );

  const getProfile = useCallback(
    async (id: string) => {
      const token = await getAccessTokenSilently();
      const result = (await API.graphql(
        graphqlOperation(queries.getProfile, { id }, token)
      )) as GraphQLResult<GetProfileQuery>;
      const profile = result?.data?.getProfile;
      return profile as Profile;
    },
    [getAccessTokenSilently]
  );

  const selectViewProfile = useCallback(
    async (p: Profile | undefined) => {
      if (typeof window !== "undefined") {
        if (p && p.id) {
          localStorage.setItem("realbrave_view_profile_id", p.id);
        } else {
          localStorage.removeItem("realbrave_view_profile_id");
        }
      }

      if (!p?.id) return setViewProfile(p);

      const token = await getAccessTokenSilently();
      const result = (await API.graphql(
        graphqlOperation(queries.getProfile, { id: p.id }, token)
      )) as GraphQLResult<GetProfileQuery>;
      const profile = result?.data?.getProfile as Profile;
      setViewProfile(profile);
    },
    [setViewProfile, getAccessTokenSilently]
  );

  const selectProfile = useCallback(
    (p: Profile | undefined) => {
      if (typeof window !== "undefined") {
        if (p && p.id) {
          localStorage.setItem("realbrave_profile_id", p.id);
        } else {
          localStorage.removeItem("realbrave_profile_id");
        }
      }
      setActiveProfile(p);
      if (
        !account?.roles?.some((r) =>
          ["teacher", "admin", "superadmin"].includes(r as string)
        )
      )
        selectViewProfile(p);
    },
    [account?.roles, selectViewProfile]
  );

  const updateAccount = useCallback(
    async (a: Partial<Account>) => {
      const token = await getAccessTokenSilently();
      const gqlUpdateAccount = (await API.graphql(
        graphqlOperation(mutations.updateAccount, { input: a }, token)
      )) as GraphQLResult<UpdateAccountMutation>;
      const account = gqlUpdateAccount?.data?.updateAccount;
      return account;
    },
    [getAccessTokenSilently]
  );

  const updateProfile = useCallback(
    async (p: Partial<Profile>, syncManifest = true) => {
      const token = await getAccessTokenSilently();
      const gqlUpdateProfile = (await API.graphql(
        graphqlOperation(mutations.updateProfile, { input: p }, token)
      )) as GraphQLResult<UpdateProfileMutation>;

      if (syncManifest) {
        await postFetcher(
          `/api/manifest/put/${PROFILE_MANIFEST_NAME}`,
          {
            value: getProfileManifest(p),
          },
          getIdTokenClaims
        );
      }
      const profile = gqlUpdateProfile?.data?.updateProfile;
      // if the active flag is being updated, update the constant contact account entry
      if (profile && (p.isActive === true || p.isActive === false)) {
        const accountProfiles = profile.account?.profiles?.items;
        if (accountProfiles) {
          // update the constant contact account state based on if any profiles are active
          const someAreActive = accountProfiles.some(
            (item) => item?.isActive === true
          );
          // console.log("someAreActive", someAreActive);
          try {
            await API.post("realbraverestapi", "/upsertconstantcontact", {
              headers: {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
              },
              body: {
                email: profile.account?.email,
                state: someAreActive ? "activeStudent" : "terminated",
              },
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
      return profile;
    },
    [getAccessTokenSilently, getIdTokenClaims]
  );

  const getProfileFromAppt = useCallback(
    async (appt: Partial<Appointment>) => {
      if (!appt.email || !appt.firstName) {
        console.error("Appointment is missing email or first name (profile).");
        return undefined;
      }

      const token = await getAccessTokenSilently();
      const { data } = (await API.graphql(
        graphqlOperation(
          queries.accountsByEmail,
          { email: appt.email.toLowerCase() },
          token
        )
      )) as GraphQLResult<AccountsByEmailQuery>;
      const results = data?.accountsByEmail?.items;
      if (!results?.length) {
        console.error(
          "could not find any account matching this appointment email",
          appt.email.toLowerCase()
        );
        return undefined;
      }
      const account = results[0];

      // find the matching profile by first name
      let profile = account?.profiles?.items.find(
        (p) => p?.name?.trim() === appt.firstName!.trim()
      ) as Profile;

      // if not first name match, take the first profile
      if (!profile) {
        profile = account?.profiles?.items[0] as Profile;
        console.warn(
          "could not find matching profile, using first profile",
          appt.email,
          account,
          profile
        );
      }

      if (!profile)
        console.warn(
          "could not find any profile in account",
          appt.email,
          account
        );
      return profile;
    },
    [getAccessTokenSilently]
  );

  const createAuthNetProfileForViewProfile = useCallback(async () => {
    if (!viewProfile || !viewProfile.account || !viewProfile.location) {
      console.error(
        "cannot create authorize.net profile without profile, account, and location"
      );
      return undefined;
    }
    const response = await postFetcher(
      "/api/authorizeDotNet/createCustomerProfile",
      {
        location: viewProfile.location,
        email: viewProfile.account?.email,
        userId: viewProfile.account?.sub,
        accountId: viewProfile.accountID,
        accountName: viewProfile.account?.name,
      },
      getIdTokenClaims
    );
    if (!response?.id) {
      console.error("createCustomerProfile error:", response);
      return undefined;
    }
    await updateProfile(
      {
        id: viewProfile.id,
        billCustomerProfileId: response.id,
      },
      false
    );
    return response;
  }, [viewProfile, getIdTokenClaims, updateProfile]);

  const value = useMemo(
    () => ({
      account,
      activeProfile,
      selectProfile,
      viewProfile,
      selectViewProfile,
      refresh,
      refreshView,
      updateAccount,
      updateProfile,
      accountSync,
      updateLessonStatus,
      isInLesson,
      updateActiveLesson,
      activeLesson,
      isLoading,
      getAccount,
      getProfile,
      getProfileFromAppt,
      useRealbraveApp,
      updateUseRealBraveApp,
      createAuthNetProfileForViewProfile,
    }),
    [
      account,
      activeProfile,
      selectProfile,
      viewProfile,
      selectViewProfile,
      refresh,
      refreshView,
      updateAccount,
      updateProfile,
      accountSync,
      updateLessonStatus,
      isInLesson,
      updateActiveLesson,
      activeLesson,
      isLoading,
      getAccount,
      getProfile,
      getProfileFromAppt,
      useRealbraveApp,
      updateUseRealBraveApp,
      createAuthNetProfileForViewProfile,
    ]
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};
