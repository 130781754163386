export const skillInstancesByProfileShallow = /* GraphQL */ `
  query SkillInstancesByProfile(
    $profileID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillInstancesByProfile(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        profileID
        templateID
        template {
          id
          parent
          creator
          name
          instrument
          category
          description
          tutorialVideos
          externalVideos
          supportingDocuments
          isDeleted
          isShared
          history
          createdAt
          updatedAt
        }
        createdAt
        percentage
        points
        isDeleted
        isInactive
        isCompleted
        responseVideos
        notes
        history
        updatedAt
      }
      nextToken
    }
  }
`;

export const listSkillTemplatesForManifest = /* GraphQL */ `
  query ListSkillTemplates(
    $filter: ModelSkillTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkillTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parent
        creator
        name
        instrument
        category
        description
        isDeleted
        isShared
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProfilesForManifest = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        name
        instrument
        isActive
        location
        billingPlan
        isDeleted
        termsAccepted
        createdAt
        updatedAt
        account {
          id
          email
          name
          isActive
          roles
        }
      }
      nextToken
    }
  }
`;
