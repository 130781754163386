import { createContext, useContext, useReducer, useState } from "react";
import { RoomType, Steps } from "../../types/twilio";
import { TwilioError } from "twilio-video";
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction,
} from "./settings/settingsReducer";
import useActiveSinkId from "./useActiveSinkId/useActiveSinkId";
import { Appointment } from "../../types/acuity";

export interface VideoStateContextType {
  error: TwilioError | Error | null | undefined;
  setError(error: TwilioError | Error | null | undefined): void;
  user?:
    | undefined
    | { displayName: undefined; photoURL: undefined; passcode?: string };
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  setRoomType(roomType: string): void;
  videoOpen: boolean;
  setVideoOpen(isOpen: boolean): void;
  appointment: Appointment;
  setAppointment(app: Appointment | null): void;
  roomStep: Steps;
  setRoomStep(step: Steps): void;
}

const VideoStateContext = createContext<VideoStateContextType | undefined>(
  null!
);
export const useVideoState = () =>
  useContext(VideoStateContext) as VideoStateContextType;

export function VideoStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null | undefined>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [roomType, setRoomType] = useState<RoomType>();
  const [videoOpen, setVideoOpen] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [roomStep, setRoomStep] = useState<Steps>(Steps.roomNameStep);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    setRoomType,
    videoOpen,
    setVideoOpen,
    appointment,
    setAppointment,
    roomStep,
    setRoomStep,
  } as VideoStateContextType;

  return (
    <VideoStateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </VideoStateContext.Provider>
  );
}
