import {
  GetTokenSilentlyOptions,
  GetIdTokenClaimsOptions,
  IdToken,
} from "@auth0/auth0-spa-js";

export const fetcher = async (
  url: string,
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions | undefined
  ) => Promise<string>
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};

export const idFetcher = async (
  url: string,
  getIdTokenClaims: (
    options?: GetIdTokenClaimsOptions | undefined
  ) => Promise<IdToken | undefined>
) => {
  try {
    const id = await getIdTokenClaims();
    if (!id) {
      throw "No id token found";
    }
    const idToken = id.__raw;
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};

export const postFetcher = async (
  url: string,
  body: any,
  getIdTokenClaims: (
    options?: GetIdTokenClaimsOptions | undefined
  ) => Promise<IdToken | undefined>,
  raw = false
) => {
  try {
    const id = await getIdTokenClaims();
    if (!id) {
      throw "No id token found";
    }
    const idToken = id.__raw;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": raw ? "application/octet-stream" : "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: raw ? body : JSON.stringify(body),
    });
    return await response.json();
  } catch (e) {
    console.error(e);
  }
};

export const fullPathImageLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  return `${src}`;
};
