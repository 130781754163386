import { createTheme } from "@mui/material/styles";

import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

const anyThemeComponents: any = {
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        "& button, & div, & span": {
          fontSize: "1.1rem",
          fontWeight: 700,
        },
        "& button": {
          borderRadius: "0.375rem",
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      cell: {
        fontWeight: 700,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        "& span": {
          marginRight: 0,
        },
      },
    },
  },
};

const theme = createTheme({
  palette: {
    primary: { main: "#fe5c38", contrastText: "#ffffff" }, // var rlb-orange
    secondary: { main: "#003e4c", contrastText: "#ffffff" }, // var rlb-green
  },
  typography: {
    fontFamily: [
      "ABeeZee",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      fontFamily: [
        "ABeeZee",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  components: {
    ...anyThemeComponents,
  },
});

export default theme;
