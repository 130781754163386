import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAWS } from "./federatedSignIn";

import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import {
  Instrument,
  Location,
  ListInstrumentsQuery,
  ListLocationsQuery,
  CreateInstrumentMutation,
  CreateLocationMutation,
  UpdateInstrumentMutation,
  UpdateLocationMutation,
  DeleteInstrumentMutation,
  DeleteLocationMutation,
  SettingsByNameQuery,
} from "../src/API";
import * as queries from "../src/graphql/queries";
import * as mutations from "../src/graphql/mutations";

type DataManagerContextType = {
  instruments: Instrument[] | undefined;
  refreshInstruments: () => Promise<void>;
  upsertInstrument: (
    instrument: Partial<Instrument>,
    del?: boolean
  ) => Promise<Instrument>;
  locations: Location[] | undefined;
  refreshLocations: () => Promise<void>;
  upsertLocation: (
    location: Partial<Location>,
    del?: boolean
  ) => Promise<Location>;
  getSetting: (name: string) => Promise<any>;
  upsertSetting: (name: string, value: string) => Promise<void>;
};

const PlatformSettingsContext = createContext<
  DataManagerContextType | undefined
>(undefined);
export const usePlatformSettings = () =>
  useContext(PlatformSettingsContext) as DataManagerContextType;

export const PlatformSettingsProvider = ({ children }: { children: any }) => {
  const [instruments, setInstruments] = useState<Instrument[]>();
  const [locations, setLocations] = useState<Location[]>();

  const { getAccessTokenSilently } = useAuth0();
  const { awsReady } = useAWS();

  const refreshInstruments = useCallback(async () => {
    if (!awsReady) return;
    const token = await getAccessTokenSilently();

    const gqlListInstruments = (await API.graphql(
      graphqlOperation(queries.listInstruments, {}, token)
    )) as GraphQLResult<ListInstrumentsQuery>;
    setInstruments(
      (gqlListInstruments?.data?.listInstruments?.items?.sort(
        (a, b) => a?.label?.localeCompare(b?.label || "") || 0
      ) as any) || []
    );
  }, [awsReady, getAccessTokenSilently]);

  const refreshLocations = useCallback(async () => {
    if (!awsReady) return;
    const token = await getAccessTokenSilently();

    const gqlListLocations = (await API.graphql(
      graphqlOperation(queries.listLocations, {}, token)
    )) as GraphQLResult<ListLocationsQuery>;
    setLocations((gqlListLocations?.data?.listLocations?.items as any) || []);
  }, [awsReady, getAccessTokenSilently]);

  useEffect(() => {
    refreshInstruments();
  }, [refreshInstruments]);

  useEffect(() => {
    refreshLocations();
  }, [refreshLocations]);

  const upsertInstrument = useCallback(
    async (instrument: Partial<Instrument>, del = false) => {
      const token = await getAccessTokenSilently();

      if (del) {
        const gqlDeleteInstrument = (await API.graphql(
          graphqlOperation(
            mutations.deleteInstrument,
            { input: location },
            token
          )
        )) as GraphQLResult<DeleteInstrumentMutation>;
        return gqlDeleteInstrument?.data?.deleteInstrument as Instrument;
      }

      if (instrument.id) {
        const gqlUpdateInstrument = (await API.graphql(
          graphqlOperation(
            mutations.updateInstrument,
            { input: instrument },
            token
          )
        )) as GraphQLResult<UpdateInstrumentMutation>;
        return gqlUpdateInstrument?.data?.updateInstrument as Instrument;
      } else {
        const gqlCreateInstrument = (await API.graphql(
          graphqlOperation(
            mutations.createInstrument,
            { input: instrument },
            token
          )
        )) as GraphQLResult<CreateInstrumentMutation>;
        return gqlCreateInstrument?.data?.createInstrument as Instrument;
      }
    },
    [getAccessTokenSilently]
  );

  const upsertLocation = useCallback(
    async (location: Partial<Location>, del = false) => {
      const token = await getAccessTokenSilently();

      if (del) {
        const gqlDeleteLocation = (await API.graphql(
          graphqlOperation(mutations.deleteLocation, { input: location }, token)
        )) as GraphQLResult<DeleteLocationMutation>;
        return gqlDeleteLocation?.data?.deleteLocation as Location;
      }

      if (location.id) {
        const gqlUpdateLocation = (await API.graphql(
          graphqlOperation(mutations.updateLocation, { input: location }, token)
        )) as GraphQLResult<UpdateLocationMutation>;
        return gqlUpdateLocation?.data?.updateLocation as Location;
      } else {
        const gqlCreateLocation = (await API.graphql(
          graphqlOperation(mutations.createLocation, { input: location }, token)
        )) as GraphQLResult<CreateLocationMutation>;
        return gqlCreateLocation?.data?.createLocation as Location;
      }
    },
    [getAccessTokenSilently]
  );

  const getSetting = useCallback(
    async (name: string) => {
      const token = await getAccessTokenSilently();
      const res = (await API.graphql(
        graphqlOperation(queries.settingsByName, { name }, token)
      )) as GraphQLResult<SettingsByNameQuery>;
      const items = res.data?.settingsByName?.items;
      if (!items || items.length === 0) return undefined;
      return items[0];
    },
    [getAccessTokenSilently]
  );

  const upsertSetting = useCallback(
    async (name: string, value: string) => {
      const token = await getAccessTokenSilently();

      const existing = await getSetting(name);
      if (existing) {
        const update = { id: existing.id, name, value };
        await API.graphql(
          graphqlOperation(mutations.updateSetting, { input: update }, token)
        );
      } else {
        const create = { name, value };
        await API.graphql(
          graphqlOperation(mutations.createSetting, { input: create }, token)
        );
      }
    },
    [getAccessTokenSilently, getSetting]
  );

  const value = useMemo(
    () => ({
      instruments,
      refreshInstruments,
      upsertInstrument,
      locations,
      refreshLocations,
      upsertLocation,
      getSetting,
      upsertSetting,
    }),
    [
      instruments,
      refreshInstruments,
      upsertInstrument,
      locations,
      refreshLocations,
      upsertLocation,
      getSetting,
      upsertSetting,
    ]
  );

  return (
    <PlatformSettingsContext.Provider value={value}>
      {children}
    </PlatformSettingsContext.Provider>
  );
};
