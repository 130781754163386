import { createContext, useCallback, useContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAWS } from "../federatedSignIn";

import { API, GraphQLResult, graphqlOperation } from "@aws-amplify/api";
import {
  SkillTemplate,
  ListSkillTemplatesQuery,
  CreateSkillTemplateMutation,
  UpdateSkillTemplateMutation,
  DeleteSkillTemplateMutation,
  GetSkillTemplateQuery,
} from "../../src/API";

import * as queries from "../../src/graphql/queries";
import * as mutations from "../../src/graphql/mutations";

import useSWR from "swr";
import { idFetcher, postFetcher } from "../../utilities/fetcher";
import { listSkillTemplatesForManifest } from "../../queries/skills";
import { useProfile } from "../profile";

import axios from "axios";
import { encode, decode } from "@msgpack/msgpack";

type SkillTemplateContextType = {
  cloneSkillTemplate: (
    templateId: string,
    creatorId: string
  ) => Promise<SkillTemplate>;
  getSkillTemplate: (id: string) => Promise<SkillTemplate>;
  isLoading: boolean;
  rebuildSkillTemplateManifest: () => Promise<void>;
  refreshSkillTemplates: () => void;
  skillTemplates: SkillTemplate[] | undefined;
  upsertSkillTemplate: (
    skillTemplate: Partial<SkillTemplate>,
    del?: boolean
  ) => Promise<SkillTemplate>;
};

const SkillTemplateContext = createContext<
  SkillTemplateContextType | undefined
>(undefined);
export const useSkillTemplates = () =>
  useContext(SkillTemplateContext) as SkillTemplateContextType;

const SKILL_TEMPLATE_MANIFEST_NAME = "skillTemplates";
const allowedInTemplateManifest = [
  "id",
  "parent",
  "creator",
  "name",
  "instrument",
  "category",
  "description",
  "isDeleted",
  "isShared",
  "createdAt",
  "updatedAt",
];

export const SkillTemplateProvider = ({ children }: { children: any }) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { awsReady } = useAWS();
  const { account, getProfile } = useProfile();

  const {
    data: skillTemplates,
    isValidating,
    mutate,
  } = useSWR(
    account?.roles?.some((r) =>
      ["teacher", "admin", "superadmin"].includes(r as string)
    )
      ? `/api/manifest/get/${SKILL_TEMPLATE_MANIFEST_NAME}`
      : null,
    (url) =>
      idFetcher(url, getIdTokenClaims)
        .then((res) => fetch(res.signedUrl))
        .then((res) => res.arrayBuffer())
        .then((res) => decode(res) as SkillTemplate[])
  );

  const rebuildManifest = useCallback(async () => {
    if (!awsReady) return;
    const token = await getAccessTokenSilently();
    const results: Partial<SkillTemplate>[] = [];
    let nextToken = "";
    do {
      const vars =
        nextToken === "" ? { limit: 200 } : { limit: 200, nextToken };
      const result = (await API.graphql(
        graphqlOperation(listSkillTemplatesForManifest, vars, token)
      )) as GraphQLResult<ListSkillTemplatesQuery>;
      const resultSet = (result?.data?.listSkillTemplates?.items ||
        []) as Partial<SkillTemplate>[];
      results.push(...resultSet);
      nextToken = result?.data?.listSkillTemplates?.nextToken || "";
    } while (nextToken !== "");

    // migrate uuid ref to name to facilitate easier searching
    for (const r of results) {
      if (r.creator && r.creator.length === 36) {
        // if uuid-v4-like
        const profile = await getProfile(r.creator);
        if (profile && profile.account && profile.account.name) {
          r.creator = profile.account.name;
        }
      }
    }

    const blob = new Blob([encode(results)]);

    try {
      const manifestSetResult = await idFetcher(
        `/api/manifest/set/${SKILL_TEMPLATE_MANIFEST_NAME}`,
        getIdTokenClaims
      );
      await axios.put(manifestSetResult.signedUrl, blob, {
        headers: { "content-type": blob.type },
      });
      mutate();
    } catch (error) {
      console.error("error", error);
    }
  }, [awsReady, getAccessTokenSilently, getIdTokenClaims, mutate, getProfile]);

  const upsertSkillTemplate = useCallback(
    async (skillTemplate: Partial<SkillTemplate>, del = false) => {
      const token = await getAccessTokenSilently();

      // shallow clone before we mutate
      skillTemplate = { ...skillTemplate };

      // clean up props that can't be passed
      if (skillTemplate.instances) delete skillTemplate.instances;
      if (skillTemplate.createdAt) delete skillTemplate.createdAt;
      if (skillTemplate.updatedAt) delete skillTemplate.updatedAt;

      if (del) {
        const gqlDeleteSkillTemplate = (await API.graphql(
          graphqlOperation(
            mutations.deleteSkillTemplate,
            { input: skillTemplate },
            token
          )
        )) as GraphQLResult<DeleteSkillTemplateMutation>;
        await postFetcher(
          `/api/manifest/put/${SKILL_TEMPLATE_MANIFEST_NAME}`,
          {
            value: { id: skillTemplate.id },
            del: true,
          },
          getIdTokenClaims
        );
        return gqlDeleteSkillTemplate?.data
          ?.deleteSkillTemplate as SkillTemplate;
      }

      const manifestEntry = Object.keys(skillTemplate)
        .filter((key) => allowedInTemplateManifest.includes(key))
        .reduce((obj: any, key) => {
          obj[key] = (skillTemplate as any)[key];
          return obj;
        }, {});

      if (skillTemplate.id) {
        const gqlUpdateSkillTemplate = (await API.graphql(
          graphqlOperation(
            mutations.updateSkillTemplate,
            { input: skillTemplate },
            token
          )
        )) as GraphQLResult<UpdateSkillTemplateMutation>;
        await postFetcher(
          `/api/manifest/put/${SKILL_TEMPLATE_MANIFEST_NAME}`,
          {
            value: manifestEntry,
          },
          getIdTokenClaims
        );
        return gqlUpdateSkillTemplate?.data
          ?.updateSkillTemplate as SkillTemplate;
      }

      const gqlCreateSkillTemplate = (await API.graphql(
        graphqlOperation(
          mutations.createSkillTemplate,
          { input: skillTemplate },
          token
        )
      )) as GraphQLResult<CreateSkillTemplateMutation>;
      await postFetcher(
        `/api/manifest/put/${SKILL_TEMPLATE_MANIFEST_NAME}`,
        {
          value: {
            ...manifestEntry,
            id: gqlCreateSkillTemplate?.data?.createSkillTemplate?.id,
          },
        },
        getIdTokenClaims
      );
      return gqlCreateSkillTemplate?.data?.createSkillTemplate as SkillTemplate;
    },
    [getAccessTokenSilently, getIdTokenClaims]
  );

  const cloneSkillTemplate = useCallback(
    async (templateId: string, creatorId: string) => {
      const token = await getAccessTokenSilently();
      const result = (await API.graphql(
        graphqlOperation(queries.getSkillTemplate, { id: templateId }, token)
      )) as GraphQLResult<GetSkillTemplateQuery>;
      const template = result?.data?.getSkillTemplate as SkillTemplate;

      const creatorProfile = await getProfile(creatorId);

      return await upsertSkillTemplate({
        category: template.category,
        creator: creatorProfile?.account?.name || creatorId,
        description: template.description,
        externalVideos: template.externalVideos,
        instrument: template.instrument,
        isShared: true,
        name: `${template.name} (Copy)`,
        parent: template.id,
        supportingDocuments: template.supportingDocuments,
        tutorialVideos: template.tutorialVideos,
      });
    },
    [getAccessTokenSilently, getProfile, upsertSkillTemplate]
  );

  const getSkillTemplate = useCallback(
    async (id: string) => {
      const token = await getAccessTokenSilently();
      const gqlGetSkill = (await API.graphql(
        graphqlOperation(queries.getSkillTemplate, { id }, token)
      )) as GraphQLResult<GetSkillTemplateQuery>;
      const template = gqlGetSkill?.data?.getSkillTemplate;
      return template as SkillTemplate;
    },
    [getAccessTokenSilently]
  );

  const value = useMemo(
    () => ({
      cloneSkillTemplate,
      getSkillTemplate,
      isLoading: isValidating,
      rebuildSkillTemplateManifest: rebuildManifest,
      refreshSkillTemplates: mutate,
      skillTemplates,
      upsertSkillTemplate,
    }),
    [
      cloneSkillTemplate,
      getSkillTemplate,
      isValidating,
      rebuildManifest,
      mutate,
      skillTemplates,
      upsertSkillTemplate,
    ]
  );

  return (
    <SkillTemplateContext.Provider value={value}>
      {children}
    </SkillTemplateContext.Provider>
  );
};
