import awsmobile from "./aws-exports";

// DO NOT PUT SENSITIVE OR SECRET VALUES HERE.
const extensions = {
  auth0Domain: "practice-pad.us.auth0.com",
  auth0ClientId: "ML9qYkzB6TvvWoBJkvZfTDdRU8nBqACP",
  auth0ApiAudience: "https://www.realbraveaudio.com/",
  auth0RolesClaim: "https://auth.practice-pad.com/roles",
  auth0MetaClaim: "https://auth.practice-pad.com/user_metadata",
  identityPoolId: "us-east-1:d98c9580-4137-4e5f-ba4a-e8557eb58d2f",
  region: "us-east-1",
};

const AWSConfig = { ...awsmobile, ...extensions };
export default AWSConfig;
